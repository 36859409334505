import Vue from  'vue';
import VueI18n from 'vue-i18n';

import sharedLocalesFr from '../locales/fr/shared.json';
import sharedLocalesNl from '../locales/nl/shared.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  silentTranslationWarn: true,
  messages: {
    ...sharedLocalesFr,
    ...sharedLocalesNl,
  },
});

export default i18n;
